import React, { useEffect } from 'react';
import { useState } from 'react';
import './AboutPage.css';
import img1 from '../Images/AboutPage/Banner-Image.jpg'
import img2 from '../Images/AboutPage/BannerImgTxt.svg'
import imgLogo from '../Images/AboutPage/ArrowColor.svg';
import img3 from '../Images/AboutPage/Transvolt-miniLogo.svg'
import img4 from '../Images/ServicesPage/ArrowColor.svg'
import imgIcon1 from '../Images/AboutPage/Icon-Our Core Beliefs.svg'
import imgIcon2 from '../Images/AboutPage/Icon-Vision and Mission.svg'
import imgIcon3 from '../Images/AboutPage/Icon-Our Values.svg'
import imgIcon4 from '../Images/AboutPage/Icon-Founding  Principles.svg'
import imgIcon5 from '../Images/AboutPage/Icon-Werecommittedto.svg'
import imgTraffic from '../Images/AboutPage/Traffice_Image.png'
import imgBus from '../Images/AboutPage/Bus_Image.png'
import imgButton from '../Images/AboutPage/LinkdinButton.svg'
import imgButtonHover from '../Images/AboutPage/LinkdinButtonHover.svg'
import arrow_descbox from "../Images/AboutPage/Arrow_descBox.svg"

import Header from '../header/Header';
import Navbar from '../header/Navbar';
import Footer from '../footer/Footer';
import axios from 'axios';
import toast from 'react-hot-toast';
const teamMembers = [
  {
    name: 'Dheeraj Jhawar',
    role: 'Co-Founder, Managing Director',
    imageUrl1: require('../Images/AboutPage/1-Dheeraj Jhawar -1.png'),
    imageUrl2: require('../Images/AboutPage/1-Dheeraj Jhawar -2.png'),
    linkedinUrl: 'https://www.linkedin.com/in/dheeraj-jhawar-5558232/',
  },
  {
    name: 'Sandeep Agarwal',
    role: 'Co-Founder, Managing Director',
    imageUrl1: require('../Images/AboutPage/2 - Sandeep - 1.png'),
    imageUrl2: require('../Images/AboutPage/2- Sandeep -2.png'),
    linkedinUrl: 'https://www.linkedin.com/in/sandeep-agarwal-b893ba99/',
  },
  {
    name: 'Debasis Mohanty ',
    role: 'Co-Founder, Chief Executive Officer',
    imageUrl1: require('../Images/AboutPage/3 - Debasis - 1.png'),
    imageUrl2: require('../Images/AboutPage/3 - Debasis -2.png'),
    linkedinUrl: 'https://www.linkedin.com/in/debasismohanty/',
  },
];

const teamMembers2 = [
  {
    name: 'Madhavi Deshpande',
    role: 'Director Gender Equality, Inclusiveness and Sustainability',
    imageUrl1: require('../Images/AboutPage/Madhavi Deshpande-1.png'),
    imageUrl2: require('../Images/AboutPage/Madhavi Deshpande-2.png'),
    linkedinUrl: 'https://www.linkedin.com/in/madhavi-deshpande-4042692/',
  },
  {
    name: 'Parag Deshmukh',
    role: 'Chief Financial Officer',
    imageUrl1: require('../Images/AboutPage/5-Parag-1.png'),
    imageUrl2: require('../Images/AboutPage/5-Parag-2.png'),
    linkedinUrl: 'https://www.linkedin.com/in/parag-deshmukh-b34860194/',
  },
  {
    name: 'Deepak Choksi',
    role: 'Chief Technology Officer',
    imageUrl1: '../Images/AboutPage/6-Deepak-1.png',
    imageUrl2: '../Images/AboutPage/6-Deepak-2.png',
    linkedinUrl: 'https://www.linkedin.com/in/deepak-choksi-02a9111a/',
  },
  {
    name: 'Saima Khalfay',
    role: 'Vice President - Human Resources',
    imageUrl1: '../Images/AboutPage/Sayma-B&W.png',
    imageUrl2: '../Images/AboutPage/Sayma-Color.png',
    linkedinUrl: 'https://www.linkedin.com/in/saima-khalfay-78128219/',
  },
  {
    name: 'Mandar Hinge',
    role: 'President Operation',
    imageUrl1: '../Images/AboutPage/8-Pandar-1.png',
    imageUrl2: '../Images/AboutPage/8-Pandar-2.png',
    linkedinUrl: 'https://www.linkedin.com/in/mandar-hinge-a60b76299/',
  },
  {
    name: 'Bhrugesh Amin',
    role: 'President Strategy',
    imageUrl1: '../Images/AboutPage/BhrugeshBW.jpg',
    imageUrl2: '../Images/AboutPage/Bhrugesh.jpg',
    linkedinUrl: 'https://www.linkedin.com/in/bhrugesh-amin-98320815/',
  },
  {
    name: 'Hemant Velhankar',
    role: 'President Engineering & Maintenance',
    imageUrl1: '../Images/AboutPage/velhankarBW.png',
    imageUrl2: '../Images/AboutPage/velhankar.png',
    linkedinUrl: 'https://www.linkedin.com/in/hemant-velhankar-61295b34/',
  },
  {
    name: 'K P Nathan',
    role: 'Vice President, Safety, Training & Compilance',
    imageUrl1: '../Images/AboutPage/9-PK_Nathan-1.png',
    imageUrl2: '../Images/AboutPage/9-PK_Nathan-2.png',
    linkedinUrl: 'https://www.linkedin.com/in/nathan-k-b9b587159/',
  },
  {
    name: 'Siddesh Rai',
    role: 'Vice President, Business Development',
    imageUrl1: '../Images/AboutPage/10-Siddhesh-1.png',
    imageUrl2: '../Images/AboutPage/10-Siddhesh-2.png',
    linkedinUrl: 'https://www.linkedin.com/in/siddesh-rai-034a7125/',
  },
];

const partners = [
  { logoSrc: 'Images/AboutPage/MBMT Parivahan Logo 1.png', name: 'Mira Bhaindar Municipal Corporation', description: 'Concessioning authority' },
  { logoSrc: 'Images/AboutPage/Ulhasnagar Logo.png', name: 'Ulhasnagar Municipal Corporation', description: 'Concessioning authority' },
  { logoSrc: 'Images/AboutPage/Bluwheelz-Logo.png', name: 'Bluwheelz', description: 'Fleet partner for SCV' },
  // ... more partners
];


const partners2 = [
  { logoSrc: 'Images/AboutPage/Eka_Logo.png', name: 'Pinnacle Mobility Solution Private Limited ', description: 'OEM (Bus Supplier) & JV Partner in Mira Bhaindar Municipal and Ulhasnagar Projects' },
  { logoSrc: 'Images/AboutPage/Shulan_logo.png', name: 'Shuzlan Energy Private Limited', description: 'Charge Point Operator for Mira Bhaindar and Ulhasnagar Project' },
  { logoSrc: 'Images/AboutPage/Star-Charger-Logo.png', name: 'Star Charge', description: 'Chargers at Mira Bhaindar Project' },
  { logoSrc: 'Images/AboutPage/seimensCharger.jpeg', name: 'Seimens Charger', description: 'Chargers at Mira Bhaindar Project' },

]


// PartnerCard component
const PartnerCard = ({ logoSrc, name, description }) => {
  return (
    <div className="partner-card">
      <div className="logo-container">
        <img src={logoSrc} alt={`${name} logo`} className="partner-logo" />
      </div>
      <div className="details-container">
        <div className="name-and-line">
          <h3 className="partner-name">{name}</h3>
          <div className="vertical-line"></div>
        </div>
        <p className="partner-description">{description}</p>
      </div>
    </div>
  );
};

// PartnersGrid component
const PartnersGrid = ({ partners }) => {
  return (
    <div className="partners-grid">
      {partners.map((partner, index) => (
        <PartnerCard
          key={index}
          logoSrc={partner.logoSrc}
          name={partner.name}
          description={partner.description}
        />
      ))}
    </div>
  );
};

const AboutPage = () => {
  const [hoveredButtonIndex, setHoveredButtonIndex] = useState(null);
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);
  const [fetchedTeamMembers, setFetchedTeamMembers] = useState([])


  const handleMouseEnter = (index, type = 'button') => {
    if (type === 'image') {
      setHoveredImageIndex(index);
    } else {
      setHoveredButtonIndex(index);
    }
  };

  const handleMouseLeave = (type = 'button') => {
    if (type === 'image') {
      setHoveredImageIndex(null);
    } else {
      setHoveredButtonIndex(null);
    }
  };

  const handleLinkedInClick = (member) => {
    window.open(member.linkedinUrl, '_blank');
  };


  useEffect(() => {
    const getTeamMembers = async () => {
      await axios.get('http://localhost:4000/team/getallmembers')
        .then(response => {
          setFetchedTeamMembers(response.data.msg)
        })
        .catch(error => {
          // toast.error('Error getting team members')
        })
    }

    getTeamMembers()
  }, [])


  return (
    <div>
      <Header />
      <Navbar />
      <div className='banner-section'>
        <img src='/Images/AboutPage/Banner-Image.jpg' className="banner-image" alt="Banner" />
        <div className='banner-text-container'>
          <h1 className='banner-text'>Welcome to</h1>
          <img className="imageBanner" src='/Images/AboutPage/Heading.svg' alt="Heading" />
        </div>
      </div>

      <div className='banner-heading'>
        where we're pioneering the charge towards a future of ZERO EMISSION mobility, with Safety and Sustainability at the forefront!
      </div>


      <div style={{ display: "flex", flexDirection: "column" }}>

        <div className='Desc-box w-full px-56 py-3 bg-[#E8F4E0]'>
          <div className='text-base md:text-lg' style={{ height: "auto", margin: "0 auto", display: "flex", alignItems: "center", fontWeight: "600", lineHeight: "35.16px", textAlign: "left", color: "#4D4D4D" }}>
            <p>Join us as we redefine the way we move, prioritizing a cleaner, greener tomorrow for all.</p>
          </div>
          <div className='text-sm md:text-base' style={{ height: "auto", margin: "0 auto", display: "flex", alignItems: "center", fontWeight: "500", lineHeight: "24px", textAlign: "left", color: "#4D4D4D" }}>
            <p>Transvolt Mobility Private Limited, or simply Transvolt, is more than just a company; it's a vision carefully crafted to lead India's electric mobility revolution.<br />
              With a strong focus on aiding the nation in achieving a "ZERO EMISSION" transportation sector, we're committed to setting new standards in safety and sustainability while driving business growth and value for all stakeholders.</p>
          </div>
          <div className='text-sm md:text-base' style={{ height: "auto", margin: "0 auto", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "500", lineHeight: "24px", textAlign: "left", color: "#4D4D4D" }}>
            <p>As the Indian government and states nationwide push for electrification in mass transportation, Transvolt stands ready to deploy top-notch policies, technologies, and financial solutions to build a sustainable business that caters to every corner of the country.</p>
          </div>
          <div className='text-sm md:text-base' style={{ height: "auto", margin: "0 auto", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "500", lineHeight: "24px", textAlign: "left", color: "#4D4D4D" }}>
            <img src={img3} className='pr-2' alt='Transvolt' height={140} width={140}></img>
            <p className='pt-7'> has been promoted by group of professionals having substantial experience of building nation and raising optimized funding for infrastructure business.
              Transvolt is working very closely with some of the large Development Financial Institutions to fund its growth plan.</p>
          </div>
          <div className='text-xl md:text-2xl leading-9 md:leading-10' style={{ height: "auto", margin: "0 auto", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "500", textAlign: "center", color: "#4D4D4D" }}>
            <p>Transvolt plans to be 5000 plus ZERO EMMISSION VEHICLE PLATFORM in next 3-5 years.</p>
          </div>
        </div>


        <div className='ParallaxImg'>
        </div>


        <div className='Obj-box bg-[#FFFFFF] flex flex-column px-32 py-20'>

          <div className='Obj-child w-full rounded-2xl bg-[#EEEEEE] m-auto flex items-center gap-x-5 p-4' style={{ height: "auto", backgroundPosition: "center" }}>
            <div className='shadow-lg shadow-[#EEEEEE]'><img className='p-10 border-2 border-[#EEEEEE] bg-white rounded-xl' src={imgIcon1} /></div>
            <div className='w-full'>
              <h2 className='text-[#25509D]'>Our Core Beliefs</h2>
              <p>At Transvolt, we believe in clean, green, and safe commuting for both present and future generations. We're dedicated to providing communities with transportation that's not just safe and efficient but also environmentally friendly.</p>
            </div>
          </div>

          <div className="Obj-child w-full rounded-2xl bg-[#EEEEEE] m-auto flex items-center gap-x-5 p-4" style={{ height: "auto", backgroundPosition: "right" }}>
            <div className='shadow-lg shadow-[#EEEEEE]'><img className='p-10 border-2 bg-white rounded-xl' src={imgIcon2} /></div>
            <div className='w-full'>
              <h2 className='text-[#25509D]'>Vision and Mission</h2>
              <p>Our goal is simple: to offer easy, convenient, and green mobility solutions that enhance the quality of life for people and communities. We're committed to running a safe, sustainable business that delivers value to our stakeholders.</p>
            </div>
          </div>

          <div className="Obj-child w-full rounded-2xl bg-[#EEEEEE] m-auto flex items-center gap-x-5 p-4" style={{ height: "auto", backgroundPosition: "center" }}>
            <div className='shadow-lg shadow-[#EEEEEE]'><img className='p-10 border-2 bg-white rounded-xl' src={imgIcon3} /></div>
            <div className='w-full'>
              <ul>
                <h2 className='text-[#25509D]'>Our Values</h2>
                <p>
                  <li>Safety First: Ensuring passenger and community safety is our priority.</li>
                  <li>Customer Commitment: We prioritize customer satisfaction to create delight.</li>
                  <li>Team Support: Fostering a collaborative environment among ourteam.</li>
                  <li>Accountability: Holding ourselves accountable for performance anddecisions.</li>
                  <li>Excellence: Striving for the highest standards in all we do.</li>
                </p>

                {/* ... Add other values here */}
              </ul>
            </div>
          </div>

          <div className="Obj-child w-full rounded-2xl bg-[#EEEEEE] m-auto flex items-center gap-x-5 p-4" style={{ height: "auto", backgroundPosition: "right" }}>
            <div className='shadow-lg shadow-[#EEEEEE]'><img className='p-10 border-2 bg-white rounded-xl' src={imgIcon4} /></div>
            <div className='w-full'>
              <h2 className='text-[#25509D]'>Founding Principles</h2>
              <p>At Transvolt, we're guided by principles of safety, integrity, fairness, and legal compliance in all our business practices. We believe in conducting ourselves with equanimity, inclusiveness, and fairness in every interaction.<br />
                At TransVolt, we operate under a strict code of ethics, prioritizing safety, integrity, and compliance with the law. Our business principles guide every decision, ensuring that our employees uphold the highest standards of ethical conduct both on and off the job</p>
              {/* ... More content */}
            </div>
          </div>

          <div className="Obj-child w-full rounded-2xl bg-[#EEEEEE] m-auto flex items-center gap-x-5 p-4" style={{ height: "auto", backgroundPosition: "center" }}>
            <div className='shadow-lg shadow-[#EEEEEE]'><img className='p-10 border-2 bg-white rounded-xl' src={imgIcon5} /></div>
            <div className='w-full'>
              <h2 className='text-[#25509D]'>We're committed to</h2>
              <p>Let's transform transportation together! At Transvolt:<br />
                1️) We team up with customers, unions, and governments to crafttop-notch zero-emission solutions.<br />
                2) ️Innovation is our heartbeat! We're always creating sleek andefficient public transport options.<br />
                3) ️Safety, efficiency, and seamlessness are non-negotiables forevery passenger journey.<br />
                4) ️Trust is our currency! We build strong, sustainable partnershipswith transport authorities.<br />
                5️) Our employees matter most! We prioritize their well-being everystep of the way.<br /><br />
                #Transvolt #ZeroEmission #InnovationLeaders"</p>
            </div>
          </div>
        </div>

        <div class="containerrr">

          <div class='flex flex-col my-5 h-[50%]'>
            <img src={imgTraffic} alt="Traffic" class="image"></img>
            <img src={imgBus} alt="Bus" class="image"></img>
          </div>

          <div class='hidDiv text-section'>
            <div class='text-box'>
              <img src='/Images/AboutPage/Arrow_Color_Transparent.svg' class="arrow-icon" />
              <p>We are taking action to combat climate change and improve local air quality by zero emission mobility solutions for the communities and our customers.</p>
            </div>
            <div class='text-box'>
              <img src='/Images/AboutPage/Arrow_Color_Transparent.svg' class="arrow-icon" />
              <p>Transvolt is dedicated to building a green platform to provide ZERO EMMISSION services</p>
            </div>
            <div class='text-box'>
              <img src='/Images/AboutPage/Arrow_Color_Transparent.svg' class="arrow-icon" />
              <p>Transvolt is fully aligned to be a significant player in supporting the global aim to provide sustainable, green transportation solutions with value creations for all the stakeholders.</p>
            </div>
          </div>
        </div>


        <div className="team-section" style={{ display: "flex", flexDirection: "column", margin: "0px", padding: "20px", marginTop: "30px" }}>
          <div style={{ height: "82px", fontSize: "40px", fontWeight: "800", lineHeight: "48px", textAlign: "left", margin: "0 auto" }}>
            <p>Founding Team</p>
          </div>
          <div className="team-members" style={{ justifyContent: "space-between", margin: "0 auto" }}>
            {teamMembers.map((member, index) => (
              <div className="team-member-card border-2 border-[#4472C4]" style={{ width: "350px", height: "500px", display: "flex", flexDirection: "column", alignItems: "center", gap: "15px", justifyContent: "center" }} key={index}>
                <img
                  src={hoveredImageIndex === index ? member.imageUrl2 : member.imageUrl1}
                  alt={member.name}
                  className="team-member-image"
                  style={{ width: "242px", height: "273px" }}
                  onMouseEnter={() => handleMouseEnter(index, 'image')}
                  onMouseLeave={() => handleMouseLeave('image')}
                />
                <div>
                  <span className="team-member-name">{member.name}</span>
                  <p className="team-member-role">{member.role}</p>
                </div>
                <div>
                  {hoveredButtonIndex === index ? (
                    <img src={imgButtonHover} alt="LinkedIn" onClick={() => handleLinkedInClick(member)} onMouseLeave={() => handleMouseLeave()} className="linkedin-button" />
                  ) : (
                    <img src={imgButton} alt="LinkedIn" onMouseEnter={() => handleMouseEnter(index)} className="linkedin-button" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='team-section' style={{ display: "flex", flexDirection: "column", justifyContent: "center", margin: "0px", padding: "20px" }}>
          <div style={{ height: "82px", fontSize: "45px", fontWeight: "700", lineHeight: "48px", textAlign: "left", margin: "0 auto" }}>
            <p>Meet the Team</p>
          </div>
          <div className="team-members mb-16">
            {teamMembers2.map((member, index) => (
              <div className="team-member-card1 flex justify-between p-3 w-[30%]" key={index}>
                <div className=''>
                  <img style={{"width":"250px", "height":"270px", "borderRadius":"0.5cm"}}
                    src={hoveredImageIndex === index ? member.imageUrl2 : member.imageUrl1}
                    alt={member.name}
                    className="team-member-image"
                    onMouseEnter={() => handleMouseEnter(index, 'image')}
                    onMouseLeave={() => handleMouseLeave('image')}
                  />
                </div>
                <div className='flex flex-col items-center'>
                  <h3 className="team-member-name1">{member.name}</h3>
                  <p className="team-member-role1">{member.role}</p>
                  <div>
                    {hoveredButtonIndex === index ? (
                      <img src={imgButtonHover} alt="LinkedIn" onClick={() => handleLinkedInClick(member)} onMouseLeave={() => handleMouseLeave()} className="linkedin-button" />
                    ) : (
                      <img src={imgButton} alt="LinkedIn" onMouseEnter={() => handleMouseEnter(index)} className="linkedin-button" />
                    )}
                  </div>
                </div>
              </div>
            ))}

            {fetchedTeamMembers.map((member, index) => (
              <div className="team-member-card1 flex justify-between p-3 w-[30%]" key={index}>
                <div>
                  <img
                    src={`https://res.cloudinary.com/dipdggpwh/image/upload${member.imageUrl}.png`}
                    alt={member.name}
                    className="team-member-image h-56 w-56"
                  />
                </div>
                <div className='flex flex-col items-center'>
                  <h3 className="team-member-name1">{member.name}</h3>
                  <p className="team-member-role1">{member.role}</p>
                  <div>
                    <img src={imgButton} onClick={() => handleLinkedInClick(member)} alt="LinkedIn" className="linkedin-button" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <h3 className='partnerBkground' style={{ textAlign: "center", fontWeight: "700", fontSize: "50px" }}>Our Partners</h3>
        <div className="partners-wrapper">
          <PartnersGrid partners={partners} />
          <PartnersGrid partners={partners2} />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AboutPage;